import { Functions, httpsCallable } from "firebase/functions";
import { metadataInterface } from "../interfaces/metadataInterface";
import { CometChat } from "@cometchat/chat-sdk-javascript";

export const fetchProfile = async (functions: Functions, uid: string): Promise<metadataInterface | null> => {
  const fetchUserProfile = httpsCallable(functions, 'fetchUserProfile');
  const userProfileResult = await fetchUserProfile({ uid });
  const userProfile = userProfileResult.data as unknown as metadataInterface;

  return userProfile;
}

export const getProfileFromMetadata = (user: CometChat.User) => {
  const metadata = user.getMetadata() as unknown as metadataInterface;

  if (metadata === undefined) {
    return {
      about: {
        gender: 'unknown',
        birthDate: 'unknown',
      }
    }
  };

  if (!metadata || typeof metadata === 'string') {
    const jsonParsedMetadata = JSON.parse(metadata as string) as metadataInterface;
    return jsonParsedMetadata;
  }

  return metadata;
}

const sanitizeMetadata = (metadata: metadataInterface): Partial<metadataInterface> => {
  const allowedFields: (keyof metadataInterface)[] = [
    'about',
    'appearance',
    'favoriteChannels',
    'settings',
    'muted',
    'gallery',
    'callStatus',
  ];

  const sanitizedMetadata: Partial<metadataInterface> = {};

  for (const field of allowedFields) {
    if (Object.prototype.hasOwnProperty.call(metadata, field)) {
      (sanitizedMetadata as any)[field] = metadata[field];
    }
  }

  // Always delete birthDate from about
  if (sanitizedMetadata.about) {
    delete sanitizedMetadata.about.birthDate;
  }

  // Additional privacy stripping if profile is private
  if (metadata.settings?.profileVisibility === "private") {
    if (sanitizedMetadata.about) {
      delete sanitizedMetadata.about.aboutText;
      delete sanitizedMetadata.about.function;
      delete sanitizedMetadata.about.relationshipStatus;
    }
    delete sanitizedMetadata.appearance;
  }

  // Always strip sensitive fields
  delete (sanitizedMetadata as any).ip;
  delete (sanitizedMetadata as any).lastFetched;
  delete (sanitizedMetadata as any).adminInfo;

  return sanitizedMetadata;
};


export const updateUserMetadata = async (functions: Functions, metadata: metadataInterface, user: CometChat.User): Promise<metadataInterface | boolean> => {
  try {
    const putMetadata = httpsCallable(functions, 'putUserMetadata');
    await putMetadata({ metadata });

    console.log("new metadata = ", sanitizeMetadata(metadata));
    user.setMetadata(sanitizeMetadata(metadata));
    await CometChat.updateCurrentUserDetails(user);

    return metadata;
  } catch (error) {
    console.error("error", error);
    return false;
  }
};

export const updateGallery = async (user: CometChat.User, galleryImages: string[]) => {
  const metadata = getProfileFromMetadata(user);
  metadata.gallery = galleryImages;
  // Delete the ip
  delete metadata.ip;
  user.setMetadata(metadata);
  await CometChat.updateCurrentUserDetails(user);
};

export const changeUserPrivacy = async (functions: Functions, user: CometChat.User, profileVisiblity: string): Promise<boolean> => {
  try {
    const togglePrivacy = httpsCallable(functions, 'changePrivacy');
    await togglePrivacy({ profileVisiblity });

    const metadata = getProfileFromMetadata(user);
    metadata.settings = {
      profileVisibility: profileVisiblity,
    }

    // Remove private fields from metadata if profile is private
    if (metadata.settings?.profileVisibility === "private" || metadata.settings?.profileVisibility === "friends") {
      delete metadata.about.aboutText;
      delete metadata.about.birthDate;
      delete metadata.about.function;
      delete metadata.about.relationshipStatus;
      delete metadata.appearance;
    }
    // Delete the ip
    delete metadata.ip;
    user.setMetadata(metadata);
    await CometChat.updateCurrentUserDetails(user);

    return true;
  } catch (error) {
    console.error("error", error);
    return false;
  }
}
